<template>
	<v-row>
		<v-col cols="12">
			<HomepageMenu :src="logoImage"></HomepageMenu>
			<v-container>
				<v-row>
					<v-col cols="12">
						<article class="page-not-found">
							<section class="page-not-found">
								<h1>Error, page not found!</h1>
								<p>
									<v-btn elevation="2" large
										><router-link to="/"
											>Homepage</router-link
										></v-btn
									>
								</p>
							</section>
						</article>
					</v-col>
				</v-row>
			</v-container>
		</v-col>
	</v-row>
</template>

<script>
import { mapGetters } from "vuex";
import HomepageMenu from "./components/HomepageMenu.vue";

export default {
	name: "Error",
	components: {
		HomepageMenu
	},
	computed: {
		...mapGetters(["theme"])
	},

	beforeMount() {
		this.$store.dispatch("getOrganizationInfo", 1);
		this.$store.dispatch("setThemeColors");
		this.$store.dispatch("setIsThemeFetched", true);
	}
};
</script>
