<template>
	<nav class="home__header">
		<v-container>
			<v-row class="centered">
				<v-col cols="12">
					<div class="home__header--wrapper">
						<router-link :to="hpLink">
							<figure><img id="logo" :src="src" /></figure>
						</router-link>
						<div
							v-if="$store.getters.isLogged"
							class="home__header--buttons"
						>
							<router-link
								to="/admin"
								class="button link-background rounded"
								>Dashboard</router-link
							>
							<div
								class="button link-background rounded primary-text"
								@click="$store.dispatch('logout')"
							>
								Logout
							</div>
						</div>
						<div v-else class="home__header--buttons">
							<router-link
								to="/login"
								class="button link-background rounded"
								>Login</router-link
							>
							<router-link
								to="/registration"
								class="button link-background rounded"
								>Register</router-link
							>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</nav>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "HomepageMenu",
	props: ["src"],
	computed: {
		...mapGetters(["organization"]),
		hpLink() {
			if (!!this.organization?.id === false) return "";
			return `/?organizationId=${this.organization.id}`;
		}
	}
};
</script>
